import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import Button from 'core/elements/button/Button'
import uuid from 'uuid'
// import AddVolumeTypeDialog from './AddVolumeTypeDialog'

const useStyles = makeStyles<Theme>((theme) => ({
  field: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    gap: 8,
    alignItems: 'center',
  },
  check: {
    position: 'relative',
    top: 14,
    cursor: 'pointer',
  },
  error: {
    color: theme.components.input.error,
    lineHeight: '16px',
  },
}))

export default function AddVolumeType({ params, updateParams, getParamsUpdater }) {
  const classes = useStyles({})
  // const [showAdd, setShowAdd] = useState(false)

  // const updateTypesFn = useCallback(
  //   (type) => {
  //     updateParams({
  //       volumeTypes: [...params.volumeTypes, type],
  //     })
  //   },
  //   [params.volumeTypes],
  // )

  const addVolumeTypeFn = useCallback(() => {
    updateParams({
      volumeTypes: [...params.volumeTypes, { name: '', id: uuid.v4() }],
    })
  }, [params.volumeTypes])

  return (
    <div>
      {/* {showAdd && (
        <AddVolumeTypeDialog
          currentVolumeTypes={params.volumeTypes}
          updateVolumeTypes={updateTypesFn}
          onClose={() => setShowAdd(false)}
        />
      )} */}
      <Button type="button" icon="plus" onClick={addVolumeTypeFn}>
        Add Volume Type
      </Button>
    </div>
  )
}
