import { makeStyles } from '@material-ui/styles'
import CodeBlock from 'core/components/CodeBlock'
import Button from 'core/elements/button/Button'
import Modal from 'core/elements/modal/Modal'
import Theme from 'core/themes/model'
import React, { useEffect, useCallback } from 'react'
import { getVirtualMachine } from './actions'

const useStyles = makeStyles<Theme>((theme) => ({
  errorBlock: {
    color: 'red',
    backgroundColor: '#fcebeb',
  },
}))

interface ErrorDialogProps {
  vmId: string
  onClose: () => void
}

const VmErrorModal = ({ vmId, onClose }: ErrorDialogProps) => {
  const classes = useStyles()
  const [copied, setCopied] = React.useState(false)
  const [error, setError] = React.useState('')
  const [traceback, setTraceback] = React.useState('')

  useEffect(() => {
    const getVmCall = async () => {
      const response: any = await getVirtualMachine({ id: vmId })
      const fault = response?.fault
      setError(fault?.message)
      setTraceback(fault?.details)
    }
    getVmCall()
  }, [vmId])

  const handleErrorCopy = useCallback(() => {
    navigator.clipboard.writeText(
      traceback
        ? `${error}

${traceback}`
        : `${error}`,
    )
    setCopied(true)
  }, [error, traceback])

  return (
    <Modal
      title="Error Details"
      onClose={onClose}
      maxWidth={1000}
      panel="dialog"
      footer={
        <>
          <Button variant="secondary" onClick={handleErrorCopy}>
            {copied ? 'Copied!' : 'Copy'}
          </Button>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </>
      }
      open
    >
      <CodeBlock className={classes.errorBlock}>{error}</CodeBlock>
      {!!traceback && <CodeBlock className={classes.errorBlock}>{traceback}</CodeBlock>}
    </Modal>
  )
}

export default VmErrorModal
