import React, { useEffect, useCallback, useMemo, useState } from 'react'
import useParams from 'core/hooks/useParams'
import FormFieldSection from 'core/components/validatedForm/FormFieldSection'
import ModalForm from 'core/elements/modal/ModalForm'
import TextField from 'core/components/validatedForm/TextField'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { customValidator } from 'core/utils/fieldValidators'
import Text from 'core/elements/Text'
import PicklistField from 'core/components/validatedForm/DropdownField'
import BackendDriverPicklist from './BackendDriverPicklist'
import KeyValuesField from 'core/components/validatedForm/KeyValuesField'
import { GET_DRIVER_CONFIG, waitSeconds } from '../helpers'

const useStyles = makeStyles<Theme>((theme) => ({}))

interface Props {
  volumeConfig: any
  currentVolumeConfigs: any[]
  updateVolumeConfigs: (config: any) => void
  onClose: () => void
  volumeType: any
}

const DRIVERS = ['NFS', 'LVM', 'Ceph', 'NetApp', 'PureStorageISCSI', 'HuaweiISCSI']

export default function CreateVolumeBackendConfigurationModal({
  volumeConfig,
  currentVolumeConfigs,
  updateVolumeConfigs,
  onClose,
  volumeType,
}: Props) {
  const [showKeyValues, setShowKeyValues] = useState(true)
  const defaultParams = useMemo(
    () =>
      volumeConfig
        ? {
            name: volumeConfig.name,
            driver: volumeConfig.driver,
            keyValues: volumeConfig.keyValues,
            customDriver: volumeConfig.customDriver,
          }
        : {
            name: '',
            driver: '',
            keyValues: [],
            customDriver: '',
          },
    [volumeConfig?.keyValues],
  )

  // const defaultParams = {
  //   name: '',
  //   driver: '',
  //   keyValues: [],
  // }
  const classes = useStyles()
  const { params, getParamsUpdater, setParams, updateParams } = useParams(defaultParams)

  const selectedDriver = (driver) => {
    if (!DRIVERS.includes(driver)) {
      return
    }

    const runCheck = async () => {
      if (!!GET_DRIVER_CONFIG[driver]) {
        setShowKeyValues(false)
        await waitSeconds(0.5)
        updateParams(GET_DRIVER_CONFIG[driver])
        setShowKeyValues(true)
      }
    }
    runCheck()
  }

  const nameValidator = useMemo(() => {
    return customValidator((value) => {
      if (!value) {
        return true
      }
      if (volumeConfig?.name === value) {
        return true
      }
      return !currentVolumeConfigs.find((config) => config?.name === value)
    }, 'A configuration with that name already exists')
  }, [currentVolumeConfigs])

  // useEffect(() => {
  //   if (volumeConfig) {
  //     updateParams({
  //       ...volumeConfig,
  //     })
  //   }
  // }, [volumeConfig])

  const submitForm = useCallback(async () => {
    const config = {
      name: params.name,
      driver: params.driver,
      keyValues: params.keyValues,
      customDriver: params.customDriver,
      volumeType: volumeType.type,
      volumeTypeId: volumeType.id,
    }
    updateVolumeConfigs(config)
    handleClose()
  }, [params, volumeType])

  const handleClose = () => {
    setParams(defaultParams)
    onClose()
  }

  return (
    <ModalForm
      title={`${volumeConfig ? 'Update' : 'Add'} Volume Backend Configuration`}
      onSubmit={submitForm}
      onClose={handleClose}
      // submitting={updating || updatingSubnet}
      // error={error || subnetError}
      submitTitle={`${volumeConfig ? 'Update' : 'Add'} Volume Backend Configuration`}
      open
    >
      <FormFieldSection title="Basic Settings">
        <TextField
          id="name"
          label="Name"
          onChange={getParamsUpdater('name')}
          value={params.name}
          validations={[nameValidator]}
          required
        />
        <PicklistField
          DropdownComponent={BackendDriverPicklist}
          name="driver"
          id="driver"
          label="Storage Driver"
          compact={false}
          value={params.driver}
          onChange={(value) => {
            getParamsUpdater('driver')(value)
            selectedDriver(value)
          }}
          required
        />
        {params.driver === 'Custom' && (
          <TextField
            id="customDriver"
            label="Custom Driver Name"
            onChange={getParamsUpdater('customDriver')}
            value={params.customDriver}
            info="Must be a fully qualified driver name"
            required
          />
        )}
      </FormFieldSection>
      <FormFieldSection title="Key Value Configurations">
        {showKeyValues && (
          <KeyValuesField
            id="keyValues"
            label="Key Values"
            value={params.keyValues}
            onChange={getParamsUpdater('keyValues')}
            addLabel="Add Key/Value Pair"
            allowMultipleValues
          />
        )}
      </FormFieldSection>
    </ModalForm>
  )
}
