import React, { useEffect, useMemo, useState } from 'react'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { keys, pick } from 'ramda'
import { routes } from 'core/utils/routes'
// import DocumentMeta from 'pf9-ui-components/built/components/DocumentMeta'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import { ArrayElement } from 'core/actions/Action'
import {
  listVirtualMachines,
  listAllVirtualMachines,
  deleteVirtualMachine,
  getConsoleEndpoint,
  getNovaVersion,
} from './actions'
import ListContainer from 'core/containers/ListContainer'
import useListAction from 'core/hooks/useListAction'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import useGlobalParams from 'core/hooks/useGlobalParams'
import { virtualMachinesSelector, allVirtualMachinesSelector } from './selectors'
import { GridViewColumn } from 'core/elements/grid/Grid'
import { SortingState } from 'core/elements/grid/hooks/useGridSorting'
import InferActionParams from 'core/actions/InferActionParams'
import { useAppSelector } from 'app/store'
import { durationBetweenDates } from 'utils/misc'
import Theme from 'core/themes/model'
import { makeStyles } from '@material-ui/styles'
import CreateVirtualMachineModal from './CreateVirtualMachineModal'
import DeleteVirtualMachineDialog from './DeleteVirtualMachineDialog'
import { GridRowMenuItemSpec } from 'core/elements/grid/hooks/useGridRowMenu'
import getGridDialogButton from 'core/elements/grid/helpers/getGridDialogButton'
import PowerVirtualMachineDialog from './PowerVirtualMachineDialog'
import ManageVolumesModal from './ManageVolumesModal'
import EditVmSecurityGroupsModal from './EditVmSecurityGroupsModal'
import SnapshotVirtualMachineDialog from './SnapshotVirtualMachineDialog'
import RenameVirtualMachineDialog from './RenameVirtualMachineDialog'
import AddFixedIpModal from './AddFixedIpModal'
import RemoveFixedIpDialog from './RemoveFixedIpDialog'
import AddFloatingIpDialog from './AddFloatingIpDialog'
import RemoveFloatingIpDialog from './RemoveFloatingIpDialog'
import MigrateVirtualMachineDialog from './MigrateVirtualMachineDialog'
import EditVmMetadataModal from './EditVmMetadataModal'
import RebuildVirtualMachineDialog from './RebuildVirtualMachineDialog'
import ResizeVirtualMachineDialog from './ResizeVirtualMachineDialog'
import ConfirmResizeDialog from './ConfirmResizeDialog'
import { GridDropdownBatchActionGroup } from 'core/elements/grid/hooks/useGridSelectableRows'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import { GridBatchActionSpec } from 'core/elements/grid/hooks/useGridSelectableRows'
import VmStateCellComponent from './VmStateCellComponent'
import Tooltip from 'core/elements/tooltip'
import { humanReadableSize } from 'openstack/helpers'
import VmInfoCards from './VmInfoCards'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { listTenants } from 'account/components/userManagement/tenants/new-actions'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import { createResourceLabelsCell } from 'k8s/components/common/entity/labels-and-annotations/helpers'
import { isAdmin } from 'app/plugins/infrastructure/components/common/helpers'
import { createGridLinkCell } from 'core/elements/grid/cells/GridLinkCell'
import { IVmDetailsPageTabs } from './vm-details/model'
import VmErrorModal from './VmErrorModal'
import PollingData from 'core/components/PollingData'

type ModelDataKey = DataKeys.OpenstackVirtualMachines
type SelectorModel = ArrayElement<ReturnType<typeof virtualMachinesSelector>>
type ActionParams = InferActionParams<typeof listVirtualMachines>
// @fixme using a type here because of https://github.com/microsoft/TypeScript/issues/15300
type Params = ActionParams & {}

const requiredParams: Array<keyof ActionParams> = []
const defaultParams: Params & SortingState = {
  orderBy: 'created',
  orderDirection: 'desc',
}

const usePrefParams = createUsePrefParamsHook<Params & TablePrefsParams>(
  'OpenstackVirtualMachines',
  listTablePrefs,
)

const searchTargets = ['name', 'id']

const IpAddressesCellComponent = ({ value }) => {
  const classes = useStyles()

  const ipAddressKeys = keys(value)
  return (
    <div className={classes.addresses}>
      {ipAddressKeys?.map((key: string) => {
        return (
          <div key={key}>
            <div>
              <b>{key}</b>
            </div>
            {value[key].map((address) => (
              <div key={address.addr}>{address.addr}</div>
            ))}
          </div>
        )
      })}
    </div>
  )
}

const FlavorCellComponent = ({ value, item }) => {
  const classes = useStyles()
  const flavor = item?.flavorDetails
  return (
    <div className={classes.inlineBlock}>
      <Tooltip
        customBody={
          <div className={classes.flavorTooltip}>
            <div>
              <b>VCPUs</b> {flavor?.vcpus}
            </div>
            <div>
              <b>Memory</b> {humanReadableSize(flavor?.ram * 1024 * 1024)}
            </div>
            <div>
              <b>Disk</b> {humanReadableSize(flavor?.disk * 1024 * 1024 * 1024)}
            </div>
          </div>
        }
      >
        <Text variant="body2" className={classes.linkAppearance}>
          {value}
        </Text>
      </Tooltip>
    </div>
  )
}

const TenantCellComponent = ({ value, item }) => {
  useListAction(listTenants)
  const tenants = useSelectorWithParams(tenantsSelector, {})
  const tenant = tenants.find((ten) => ten.id === value)
  return <Text variant="body2">{tenant?.name || tenant?.id || value}</Text>
}

const vmColumns = ({ setErrorVm }): GridViewColumn<SelectorModel>[] => [
  {
    key: 'name',
    label: 'Name',
    width: 'medium',
    CellComponent: createGridLinkCell({
      routeToFn: ({ id }) =>
        routes.openstack.vmDetails.path({ id, tab: IVmDetailsPageTabs.Overview }),
    }),
  },
  {
    key: 'id',
    label: 'UUID',
    display: false,
  },
  {
    key: 'ownerName',
    label: 'Owner',
  },
  {
    key: 'tenantName',
    label: 'Tenant',
    CellComponent: TenantCellComponent,
  },
  {
    key: 'state',
    label: 'State',
    CellComponent: (props) => <VmStateCellComponent {...props} setErrorVm={setErrorVm} />,
  },
  {
    key: 'addresses',
    label: 'IP Addresses',
    CellComponent: IpAddressesCellComponent,
  },
  {
    key: 'flavorName',
    label: 'Flavor',
    CellComponent: FlavorCellComponent,
  },
  {
    key: 'imageName',
    label: 'Source Image',
  },
  {
    key: 'OS-EXT-SRV-ATTR:hypervisor_hostname',
    label: 'Host',
    // Todo: cell component with link
  },
  {
    key: 'volumeNames',
    label: 'Volumes',
    render: (names) => names?.join(', '),
    // Todo: cell component with links
  },
  {
    key: 'created',
    label: 'Age',
    formatFn: (value) => durationBetweenDates({ labels: ['d'] })(value),
  },
  {
    key: 'security_groups',
    label: 'Security Groups',
    render: (groups) => groups?.map((group) => group?.name).join(', '),
  },
  {
    key: 'serverGroup',
    label: 'Server Group',
    render: (group) => (group ? `${group?.name} (${group?.policies?.[0]})` : null),
  },
  {
    key: 'metadata',
    label: 'Metadata',
    CellComponent: createResourceLabelsCell({ type: 'table', separator: '=' }),
  },
]

export default function VirtualMachinesListPage() {
  const { allParams: params, updateGlobalParams, getParamsUpdater } = useGlobalParams(
    usePrefParams,
    defaultParams,
  )
  const classes = useStyles()
  const [selectedVms, setSelectedVms] = useState<SelectorModel[]>(null)
  const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
  const [showAttachVolumeModal, setShowAttachVolumeModal] = useState<boolean>(false)
  const [allTenants, setAllTenants] = useState(false)
  const [errorVm, setErrorVm] = useState<string>(null)

  const { message, loading, reload } = useListAction(listVirtualMachines, {
    params,
    requiredParams,
  })
  const data = useAppSelector(virtualMachinesSelector)
  const { message: allMessage, loading: allLoading, reload: allReload } = useListAction(
    listAllVirtualMachines,
    {
      params,
      requiredParams,
    },
  )
  const allData = useAppSelector(allVirtualMachinesSelector)

  useEffect(() => {
    if (allTenants) {
      reload(true)
      allReload(true)
    }
  }, [allTenants])

  useEffect(() => {
    getNovaVersion()
  }, [])

  const dropdownBatchActions: GridDropdownBatchActionGroup<SelectorModel>[] = useMemo(
    () => [
      {
        icon: 'power-off',
        label: 'Power Actions',
        actions: [
          {
            label: 'Start',
            icon: 'circle-play',
            cond: (selected) => {
              return selected.some((vm) => ['shutoff', 'stopped'].includes(vm?.state))
            },
            BatchActionButton: getGridDialogButton(
              PowerVirtualMachineDialog,
              { action: 'start' },
              { className: classes.dropdownAction },
            ),
          },
          {
            label: 'Stop',
            icon: 'stop',
            cond: (selected) => {
              return selected.some((vm) => vm?.state === 'active')
            },
            BatchActionButton: getGridDialogButton(
              PowerVirtualMachineDialog,
              { action: 'stop' },
              { className: classes.dropdownAction },
            ),
          },
          {
            label: 'Reboot',
            icon: 'repeat',
            cond: (selected) => {
              return selected.some((vm) => vm?.state === 'active')
            },
            BatchActionButton: getGridDialogButton(
              PowerVirtualMachineDialog,
              { action: 'reboot' },
              { className: classes.dropdownAction },
            ),
          },
          {
            label: 'Suspend',
            icon: 'pause',
            cond: (selected) => {
              return selected.some((vm) => vm?.state === 'active')
            },
            BatchActionButton: getGridDialogButton(
              PowerVirtualMachineDialog,
              { action: 'suspend' },
              { className: classes.dropdownAction },
            ),
          },
          {
            label: 'Resume',
            icon: 'play',
            cond: (selected) => {
              return selected.some((vm) => vm?.state === 'suspended')
            },
            BatchActionButton: getGridDialogButton(
              PowerVirtualMachineDialog,
              { action: 'resume' },
              { className: classes.dropdownAction },
            ),
          },
        ],
      },
      {
        icon: 'square-ellipsis',
        label: 'Other',
        actions: [
          {
            label: 'Rename',
            icon: 'edit',
            cond: (selected) => selected.length === 1,
            BatchActionButton: getGridDialogButton(RenameVirtualMachineDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            label: 'Snapshot',
            icon: 'camera',
            cond: (selected) => selected.length === 1,
            BatchActionButton: getGridDialogButton(SnapshotVirtualMachineDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            label: 'Rebuild',
            icon: 'screwdriver-wrench',
            cond: (selected) => selected.length === 1,
            BatchActionButton: getGridDialogButton(RebuildVirtualMachineDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            label: 'Add Private IP',
            icon: 'square-plus',
            cond: (selected) => selected.length === 1,
            BatchActionButton: getGridDialogButton(AddFixedIpModal, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            label: 'Remove Private IP',
            icon: 'square-minus',
            cond: (selected) => selected.length === 1,
            BatchActionButton: getGridDialogButton(RemoveFixedIpDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            label: 'Add Public IP',
            icon: 'square-plus',
            cond: (selected) => selected.length === 1,
            BatchActionButton: getGridDialogButton(AddFloatingIpDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            label: 'Remove Public IP',
            icon: 'square-minus',
            cond: (selected) => selected.length === 1,
            BatchActionButton: getGridDialogButton(RemoveFloatingIpDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            label: 'Edit Security Groups',
            icon: 'lock',
            cond: (selected) => selected.length === 1,
            BatchActionButton: getGridDialogButton(EditVmSecurityGroupsModal, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            label: 'Edit Metadata',
            icon: 'tags',
            cond: (selected) => selected.length === 1,
            BatchActionButton: getGridDialogButton(EditVmMetadataModal, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            label: 'Resize',
            icon: 'up-right-and-down-left-from-center',
            cond: (selected) => selected.length === 1,
            BatchActionButton: getGridDialogButton(ResizeVirtualMachineDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            label: 'Confirm Resize',
            icon: 'box-check',
            cond: (selected) => selected.length === 1 && selected[0]?.state === 'resized',
            BatchActionButton: getGridDialogButton(ConfirmResizeDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            label: 'Live Migrate',
            icon: 'database',
            BatchActionButton: getGridDialogButton(MigrateVirtualMachineDialog, null, {
              className: classes.dropdownAction,
            }),
          },
          {
            label: 'Manage Volumes',
            icon: 'hard-drive',
            cond: (vms) =>
              vms.length === 1 && ['active', 'suspended', 'stopped'].includes(vms[0].state),
            BatchActionButton: getGridDialogButton(ManageVolumesModal, null, {
              className: classes.dropdownAction,
            }),
          },
        ],
      },
    ],
    [classes],
  )

  const batchActions: GridBatchActionSpec<SelectorModel>[] = [
    {
      cond: (vm) => {
        return vm?.length === 1
      },
      label: 'Console',
      icon: 'terminal',
      handleAction: ([vm]) => {
        const openConsole = async () => {
          const url = await getConsoleEndpoint({ id: vm.id })
          window.open(url)
        }
        openConsole()
      },
      refreshAfterSuccess: true,
    },
    // {
    //   // cond: (vm) => {
    //   //   !!vm
    //   // },
    //   label: 'Attach Volume',
    //   icon: 'plus',
    //   handleAction: ([vm]) => {
    //     setSelectedVm(vm)
    //     setShowAttachVolumeModal(true)
    //   },
    //   refreshAfterSuccess: true,
    // },
  ]

  // Adding this in later when I figure out bug
  // const rowMenuItems: Array<GridRowMenuItemSpec<SelectorModel>> = [
  //   {
  //     label: 'Delete',
  //     icon: 'trash-alt',
  //     handleClick: (vm) => {
  //       setSelectedVm(vm)
  //       setShowDeleteDialog(true)
  //     },
  //     refreshAfterSuccess: true,
  //     hideIfDisabled: true,
  //   },
  //   {
  //     label: 'Attach Volume',
  //     icon: 'plus',
  //     handleClick: (vm) => {
  //       setSelectedVm(vm)
  //       setShowAttachVolumeModal(true)
  //     },
  //     refreshAfterSuccess: true,
  //     hideIfDisabled: true,
  //   },
  // ]

  return (
    <>
      <DocumentMeta title="Virtual Machines" />
      <PollingData
        hidden
        loading={(!allTenants && loading) || (allTenants && loading)}
        onReload={allTenants ? allReload : reload}
        refreshDuration={1000 * 30}
      />
      <CreateVirtualMachineModal addRoute={routes.openstack.deployVm} />
      {showDeleteDialog && (
        <DeleteVirtualMachineDialog onClose={() => setShowDeleteDialog(false)} rows={selectedVms} />
      )}
      {errorVm && <VmErrorModal vmId={errorVm} onClose={() => setErrorVm(null)} />}
      {!allTenants && !!data?.length && <VmInfoCards vms={data} />}
      {allTenants && !!allData?.length && <VmInfoCards vms={allData} />}
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.OpenstackVirtualMachines}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={(!allTenants && loading) || (allTenants && allLoading)}
        loadingMessage={(!allTenants && message) || (allTenants && allMessage)}
        onRefresh={allTenants ? allReload : reload}
        data={allTenants ? allData : data}
        columns={vmColumns({ setErrorVm })}
        addUrl={routes.openstack.deployVm.path()}
        addText="Deploy Virtual Machine"
        getParamsUpdater={getParamsUpdater}
        deleteAction={deleteVirtualMachine}
        deleteCond={(vms) => {
          return vms.length > 0
        }}
        // This requires DeleteDialogComponent to also trigger list deselection
        // DeleteDialogComponent={DeleteVirtualMachineDialog}
        batchActions={batchActions}
        dropdownBatchActions={dropdownBatchActions}
        // Some bug when rowMenuItems is active?
        // rowMenuItems={rowMenuItems}
        extraHeaderContent={
          isAdmin() ? (
            <ToggleSwitch
              active={allTenants}
              onClick={() => setAllTenants(!allTenants)}
              label="View VMs in All Tenants"
            />
          ) : null
        }
        multiSelection
        // showRowMenuForSingleRowActions
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  addresses: {
    display: 'grid',
    gap: 8,
  },
  dropdownAction: {
    background: theme.components.dropdown.background,
    border: 'none',
    '& .button-text': {
      color: theme.components.dropdown.color,
      justifyContent: 'start',
    },
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  inlineBlock: {
    display: 'inline-block',
  },
  flavorTooltip: {
    padding: 8,
  },
  linkAppearance: {
    color: theme.palette.primary.main,
  },
}))
